import React from 'react'
import Slider from "react-slick";
import Icon from '../../global/icons/standard-icon'
import Slide from './slide'

class homeServiceSlider extends React.Component {
    constructor(props) {
        super(props)
        
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() { this.slider.slickNext(); }

    previous() {  this.slider.slickPrev(); }

    
    render() {
      const settings = {
        dots: true,
        dotsClass: "homeservices-slider__dots",
        arrows: false,
        infinite: true,
        fade: false,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 6000,
        slidesToShow: 1,
        slidesToScroll: 1
      };       

        return (
            <div className="row expanded collapse homeservices-slider--bg no-print">
                <div className="small-12 medium-push-1 medium-10 large-push-0 large-6 columns print-stretch">
                    <div className="homeservices-slider bg-dark">
                        <Slider {...settings} ref={ref => (this.slider = ref)}>
                            <Slide 
                                heading="experience"
                                content="state_of_the_art_solutions_para"
                            />

                            <Slide 
                                heading="customisation"
                                content="customisable_approach_para"
                            />

                            <Slide 
                                heading="support"
                                content="we_provide_fanantical_support_para"
                            />
                        </Slider>
                    </div>
                </div>
                
                <div className="small-12 large-6 columns">
                    <div className="rel">
                        <div className="homeservices-slider__controls">
                            <button className="homeservices-slider__controls--prev" onClick={this.previous}>
                                <Icon icon="chevron-left" />
                            </button>

                            <button className="homeservices-slider__controls--next" onClick={this.next}>
                                <Icon icon="chevron-right" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default homeServiceSlider



