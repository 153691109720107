import React from "react";

import * as d3 from "d3";
import axios from "axios";
import * as topojson from "topojson";
import * as d3geo from "d3-geo";
import _ from "lodash";
import network from "../../../utils/network";
import globalStore from "../../../utils/globalStore";
import "isomorphic-fetch";
import { FormattedMessage } from "react-intl";

class Globe extends React.Component {
	constructor(props) {
		super(props);
		this.canvas = null;
		d3.geo = d3geo;
		this.cvns = null
		this.colorVariants = [
			"#1E90FF",
			"#1E90FF",
			"#095fb4",
			"#004180",
			"#065cb1",
			"#0a6cce",
			"#03A9F4",
			"#004180"
		];
		this.state = {
			popover: {
				position: {
					x: 0,
					y: 0
				},
				visibility: 0,
				data: {}
			}
		};
	}

	updateRef(ref) {
		this.cvns = ref;
	}

	bindMouseMove = e => {
		/*
        let newState = this.state;
        newState.popover.position = {
            x: e.x,
            y: e.y
        }
        this.setState(newState);
        */
	};

	componentWillUnmount() {
		document.removeEventListener("mousemove", this.bindMouseMove);
	}

	componentDidMount() {
		document.addEventListener("mousemove", this.bindMouseMove);
		//
		// Configuration
		//
		this.rotationDelay = 6000;
		this.scaleFactor = 1;
		this.degPerSec = 25;
		this.angles = { x: -20, y: 40, z: 0 };
		this.colorWater = "#f8f8f8";
		this.colorLand = "#ccc";
		this.colorGraticule = "#f8f8f8";
		this.colorCountry = "red";
		this.locationData = [];

		//
		// Handler
		//

		this.hasStopped = false;
		this.enter = countryEnter => {
			let currentState = this.state;
			let country = this.countryList.find(c => {
				return c.id === countryEnter.id;
			});

			if (!country || !country.name) return false;

			const thislocation = _.find(this.locationData, loc => {
				return loc.countryName.toLowerCase() === country.name.toLowerCase();
			});

			if (thislocation) {
				this.stopRotation();
				this.hasStopped = true;
				currentState.popover.visibility = 1;
				currentState.popover.data = thislocation;
			} else {
				if (this.hasStopped) {
					this.startRotation();
				}
				this.hasStopped = false;
				currentState.popover.visibility = 0;
			}

			this.setState(currentState);
		};

		this.leave = country => {
			let currentState = this.state;
			currentState.popover.visibility = 0;
			this.setState(currentState);
		};

		//
		// Variables
		//

		this.canvas = d3.select(this.cvns);
		this.context = this.cvns.getContext("2d");
		this.water = { type: "Sphere" };
		this.projection = d3.geoOrthographic().precision(0.1);
		this.graticule = d3.geoGraticule10();
		this.path = d3.geoPath(this.projection).context(this.context);
		this.v0 = null; // Mouse position in Cartesian coordinates at start of drag gesture.
		this.r0 = null; // this.projection rotation as Euler angles at start.
		this.q0 = null; // this.projection rotation as versor at start.
		this.lastTime = d3.now();
		this.degPerMs = this.degPerSec / 1000;
		this.width = null; this.height = null;
		this.land = null; this.countries = null;
		this.countryList = null;
		this.autorotate = null; this.now = null; this.diff = null; this.rotation = null;
		this.currentCountry = null;

		//
		// Functions
		//

		this.setAngles = () => {
			var rotationn = this.projection.rotate();
			rotationn[0] = this.angles.y;
			rotationn[1] = this.angles.x;
			rotationn[2] = this.angles.z;
			this.projection.rotate(rotationn);
		};

		this.scale = () => {
			const { clientWidth, clientHeight } = document.documentElement;
    
            this.height = clientWidth < 1380 ? 500 : 600;
            this.width = clientWidth < 1380 ? 500 : 600;

            this.height = clientWidth < 630 ? 320 : this.height;
            this.width = clientWidth < 630 ? 320 : this.width;

			this.canvas.attr("width", this.width).attr("height", this.height);
			this.projection
				.scale((1 * Math.min(this.width, this.height)) / 2)
				.translate([this.width / 2, this.height / 2]);
			this.renderGlobe();
		};

		this.renderGlobe = () => {
			if (!this.cvns) return false;

			this.context = this.cvns.getContext("2d");
			this.context.clearRect(0, 0, this.width, this.height);
			this.fill(this.water, this.colorWater);
			this.stroke(this.graticule, this.colorGraticule);
			this.fill(this.land, this.colorLand);

			_.each(this.highlightData, highlight => {
				var focusedCountry = this.country(this.countries.features, highlight);
				this.fill(focusedCountry, highlight.color);
			});
			return;
		};

		this.fill = (obj, color) => {
			this.context.beginPath();
			this.path(obj);
			this.context.fillStyle = color;
			this.context.fill();
		};

		this.stroke = (obj, color) => {
			this.context.beginPath();
			this.path(obj);
			this.context.strokeStyle = color;
			this.context.stroke();
		};

		this.rotate = elapsed => {
			this.now = d3.now();
			this.diff = this.now - this.lastTime;
			if (this.diff < elapsed) {
				this.rotation = this.projection.rotate();
				this.rotation[0] += this.diff * this.degPerMs;
				this.projection.rotate(this.rotation);
				this.renderGlobe();
			}
			this.lastTime = this.now;
		};

		this.startRotation = delay => {
			this.autorotate.restart(this.rotate, delay || 0);
		};

		this.stopRotation = () => {
			this.autorotate.stop();
		};

		this.loadData = cb => {
			const { world, countryData } = globalStore;
			if (world && countryData) {
				return cb(world, countryData);
			}

			var countryInfo = [];
			axios
				.get(`https://unpkg.com/world-atlas@1.1.4/world/110m.json`)
				.then(world => {
					d3.tsv(
						`https://gist.githubusercontent.com/mbostock/4090846/raw/07e73f3c2d21558489604a0bc434b3a5cf41a867/world-country-names.tsv`,
						data => {
							let dataId = data.id;
							if (dataId.length == 1) {
								data.id = "00" + data.id;
							}
							if (dataId.length == 2) {
								data.id = "0" + data.id;
							}
							countryInfo.push(data);
						}
					);

					globalStore["world"] = world.data;
					globalStore["countryData"] = countryInfo;

					cb(world.data, countryInfo);
				});
		};

		// https://github.com/d3/d3-polygon
		this.polygonContains = (polygon, point) => {
			var n = polygon.length;
			var p = polygon[n - 1];
			var x = point[0],
				y = point[1];
			var x0 = p[0],
				y0 = p[1];
			var x1, y1;
			var inside = false;
			// for (var i = 0; i < n; ++i) {
			// 	(p = polygon[i]), (x1 = p[0]), (y1 = p[1]);
			// 	if (y1 > y !== y0 > y && x < ((x0 - x1) * (y - y1)) / (y0 - y1) + x1)
			// 		inside = !inside;
			// 	(x0 = x1), (y0 = y1);
			// }
			for (var i = 0; i < n; ++i) {
				p = polygon[i];
				x1 = p[0];
				y1 = p[1];
				if (y1 > y !== y0 > y && x < ((x0 - x1) * (y - y1)) / (y0 - y1) + x1)
					inside = !inside;
				x0 = x1;
				y0 = y1;
			}
			return inside;
		};

		this.getCountry = event => {
			var pos = this.projection.invert(d3.mouse(event));
			return this.countries.features.find(f => {
				return f.geometry.coordinates.find(c1 => {
					return (
						this.polygonContains(c1, pos) ||
						c1.find(c2 => {
							return this.polygonContains(c2, pos);
						})
					);
				});
			});
		};

		//
		// Initialization
		//

		this.setAngles();
		this.country = (cnt, country) => {
			const thisCountryId = _.find(this.countryList, cntry => {
				return cntry.name === country.country.countryName;
			});
			if (!thisCountryId) return;
			for (var i = 0, l = cnt.length; i < l; i++) {
				if (cnt[i].id == thisCountryId.id) {
					return cnt[i];
				}
			}
		};

		this.loadData((world, cList) => {
			this.highlightData = [];

			// network.get("partner-locations", data => {
				this.locationData = [{
					"countryName": "Nigeria",
				},{
					"countryName": "United Kingdom"
				},{
					"countryName": "Ghana"
				}, {
					"countryName": "South Africa",
				}];
				_.each(this.locationData, country => {
					this.highlightData.push({
						country: country,
						color: _.sample(this.colorVariants)
					});
				});
			// });

			const partnerLocations = 

			this.land = topojson.feature(world, world.objects.land);
			this.countries = topojson.feature(world, world.objects.countries);

			this.countryList = cList;
			window.addEventListener("resize", this.scale);
			this.scale();
			this.autorotate = d3.timer(this.rotate);
		});

		this.mousemove = e => {
			if(!this.context.canvas) return;

			var c = this.getCountry(this.context.canvas);
			if (!c) {
				if (this.currentCountry) {
					this.leave(this.currentCountry);
					this.currentCountry = undefined;
					this.renderGlobe();
					this.startRotation();
					this.hasStopped = false;
				}
				return;
			}
			if (c === this.currentCountry) {
				return;
			}
			this.currentCountry = c;
			this.renderGlobe();
			this.enter(c);
		};

		this.canvas.on("mousemove", null);
		this.canvas.on("mousemove", this.mousemove);
		this.canvas.on('mousedown.drag', null);
	}
	render() {
		const { position, visibility, data } = this.state.popover;
		const cursorStyle = { cursor: this.hasStopped ? "nw-resize" : "default" };
		const popoverStyles = {
			right: visibility == 1 ? "0px" : "-400px",
			opacity: visibility
		};

		return (
			<section className="homeglobe bg-white" style={cursorStyle}>
				<div className="homeglobe-content print-content">
					<div className="homeglobe-text">
						<em>
							<FormattedMessage id="innovative_solutions_used_in" />
						</em>
						<h2 className="dark heading">
							<FormattedMessage id="trusted_technology" />
						</h2>
						<p className="dark">
							<FormattedMessage id="we_ensure_you_para" />
						</p>
					</div>
					<canvas
						className="homeglobe-canvas"
						ref={ref => {
							this.updateRef(ref);
						}}
						id="globe"
					/>
					<div className="popover" style={popoverStyles}>
						<h5 className="popover-title">{data.countryName}</h5>
						{data.companyName &&
							data.companyName !== "" && (
								<a href={data.webAddress} className="button">
									{data.companyName} - {data.webAddress}
								</a>
							)}
					</div>
				</div>
			</section>
		);
	}
}

export default Globe;
