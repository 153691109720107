import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from '../../../i18n'


const Card = ({ subTitle, mainTitle, featImage, link }) => (
    <Link to={link} className="homeprod-card">
        {/* Image */}
        <div className="homeprod-card__image">
            <img src={featImage} alt="Product Card Image"/>
        </div>
        {/* Headings */}
        <div className="homeprod-card__content">
            <em className="noline light"><FormattedMessage id={subTitle} /> </em>
            <h4 className="light"><FormattedMessage id={mainTitle} /> </h4>
        </div>
    </Link>
)

export default Card



