import React from 'react'
import Equalizer from 'react-equalizer'
import Card from './card'


// Vars
var large = "(min-width: 1024px)";

const Products = ({cards}) => (
    <section className="homeprod bg-white">
        <div className="row expanded collapse">
            <div className="small-12 xlarge-centered xlarge-11 xxlarge-10 columns print-products">
                <Equalizer
                    className="row expanded"
                    byRow={false}
                    property="height"
                    enabled={() => window.matchMedia(large).matches}
                >

                    {cards.map(card => (
                        <div className="large-2-5 columns" key={card.mainTitle}>
                            <Card
                                subTitle={card.subTitle}
                                mainTitle={card.mainTitle}
                                featImage={card.featImage}
                                link={card.link}
                            />
                        </div>
                    ))}
                    
                </Equalizer>
            </div>
        </div>
    </section>
);

export default Products



