import React from 'react'
import Equalizer from 'react-equalizer'
import Ornament from '../../images/logo-half.svg'
import exampleImage from '../../images/example-news.jpg'
import network from '../../utils/network';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl'
import { Link } from '../../i18n'


var large = "(min-width: 1024px)";

const case_studies = {
      "_id": "5be2b45ca831e16ce95f9f71",
      "slug": "/case-studies/partner-mgt-software",
      "title": "Partner Management Software",
      "image": {
        "public_id": "eo6dj29xohhy9d6m8lf7",
        "version": 1541758502,
        "signature": "73f4eafbb58aad41a4c8eac922cf59d8f955396d",
        "width": 1200,
        "height": 600,
        "format": "jpg",
        "resource_type": "image",
        "url": "https://images.unsplash.com/photo-1573164573938-c9a3db2e84ff?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&h=600",
        "secure_url": "https://images.unsplash.com/photo-1573164573938-c9a3db2e84ff?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&h=600"
      },
      "state": "published"
    }

class News extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            news: {
                title:'',
                image: { 
                    secure_url: '' 
                }
            },
            news:case_studies,
        }
    }

    getNodes(equalizerComponent, equalizerElement) {
        return [
          this.refs.thisThing,
          this.refs.thatThing
        ]
      }

    
    componentWillMount() {
        let {state} = this;

        // check URL for article slug and show single article view
        // network.get('events', (data) => {
        //     state.news = _.find(data, (article) => { return _.has(article, 'image') });
        //     this.setState(state);
        // });  
    }


    render() {

        const {news} = this.state;

        return(
            <section className="homenews bg-white mpad no-print">
                {/* Ornament */}
                <div className="homenews-ornament">
                    <img src={Ornament} alt="Logo Ornament"/>
                </div>

                {/* News Content */}
                <Equalizer
                    className="row expanded collapse"
                    enabled={() => window.matchMedia(large).matches}
                    nodes={this.getNodes.bind(this)}
                >

                    {/* Latest Post */}
                    <div className="small-12 large-push-1 large-5 columns print-news__image" ref="thisThing">
                        { news && news.slug && 
                            <Link to={news.slug} className="homenews-post mpad">
                                {/* Image */}
                                <div className="homenews-post__image">
                                    <img src={news.image.secure_url} alt=""/>
                                </div>

                                {/* Content */}
                                <div className="homenews-post__content">
                                    <em className="noline">Case Study</em>
                                    <span>{news.title}</span>
                                </div>
                        </Link> 
                        }
                    </div>
                    
                    {/* News Content */}
                    <div className="small-12 large-pull-1 large-4 columns print-news__content">
                        <div className="row expanded">
                            <div className="small-12 medium-push-1 medium-10 large-push-0 large-12 columns">
                                <div className="homenews-content__wrap">
                                    <div className="homenews-content">
                                        <header>
                                            <em>
                                                <FormattedMessage id="innovative_solutions_used_in"/>
                                            </em>

                                            <h2 className="dark">
                                                <FormattedMessage id="brilliant_case_studies"/>
                                            </h2>
                                        </header>

                                        <main>
                                            <Link to="/events" className="button dark">
                                                <span />
                                                <FormattedMessage id="satisfied_clients_para" tagName="div"/>
                                            </Link>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Equalizer>
            </section>
        );
    }
}
export default News



