import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from '../../../i18n'
import Slider from "react-slick";
import { useRef } from 'react';


const settings = {
    dots: true,
    dotsClass: "homeservices-slider__dots",
    arrows: false,
    infinite: true,
    fade: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 20000,
    slidesToShow: 1,
    slidesToScroll: 1
  };   

const OurServices = () => {

    const sliderRef = useRef(null);

    return ( <div className="row expanded">
                    {/* Heading */}
                    <div className="small-12 medium-push-1 medium-10 large-push-1 large-4 xlarge-3 columns print-content">
                        <div className="homeservices-header pad-bot-small mpad">
                            <h2><FormattedMessage id="services_we_provide" /></h2>
                        </div>
                    </div>

                    {/* Content */}
                    <div className="small-12 medium-pull-1 medium-10 large-pull-1 large-7 columns print-content our-services-slider">
                        <div className="homeservices-slider">
                            <Slider {...settings} ref={sliderRef}>
                                <div className="homeservices-content mpad">
                                    <p className="light"><FormattedMessage id="accounting_distribution_field_sales_ecommerce_solutions" /></p>
                                </div>
                                <div className="homeservices-content mpad">
                                    <p className="light"><FormattedMessage id="our_services_has_been_designed_para" /></p>
                                </div>
                                <div className="homeservices-content mpad">
                                    <p className="light"><FormattedMessage id="our_integrated_service_para" /></p>
                                </div>
                            </Slider>
                        </div>
                    
                        {/* <div className="homeservices-content mpad">
                            <p className="light"><FormattedMessage id="accounting_distribution_field_sales_ecommerce_solutions" /></p>
                            <Link to="/solutions" className="button"><span></span><FormattedMessage tagName="div" id="view_all_services" /></Link>
                        </div> */}
                    </div>
                </div>
            )
        }

export default OurServices;