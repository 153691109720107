import React from 'react'
import Header from '../components/global/header/header'
import Hero from '../components/heros/home'
import Intro from '../components/home/intro'
import Products from '../components/home/products/products'
import Services from '../components/home/services'
import News from '../components/home/news'
import Globe from '../components/home/globe/interactive-globe'

import CallToAction from '../components/global/cta'
import Footer from '../components/global/footer/footer'
import PageTransition from 'gatsby-plugin-page-transitions'

import productImageOne from '../images/business-solutions/accounting.jpg'
import productImageTwo from '../images/business-solutions/online-distribution.jpg'
import productImageThree from '../images/business-solutions/solution-retail.jpg'
import productImageFour from '../images/business-solutions/ecommerce.jpg'
import productImageFive from '../images/business-solutions/custom-made-solutions.jpg'

import ctaImage from '../images/7Z8A0083_Output.jpg'

import { withIntl } from '../i18n'


const cards =  [
  {
    subTitle:"small_business",
    mainTitle:"accounting",
    featImage: productImageOne,
    link: "/solutions/accounting"
  },

  {
    subTitle:"online",
    mainTitle:"distribution",
    featImage: productImageTwo,
    link: "/case-studies"
  },

  {
    subTitle:"retail_and",
    mainTitle:"field_sales",
    featImage: productImageThree,
    link: "/case-studies"
  },
  {
    subTitle:"group_collections",
    mainTitle:"ecommerce",
    featImage: productImageFour,
    link: "/solutions/ecommerce"
  },
  {
    subTitle:"custom_made",
    mainTitle:"solutions",
    featImage: productImageFive,
    link: "/case-studies"
  }
]

class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navOpen: false,
      chatOpen: false,
      mapsOpen: false,
      contactOpen: false,
    };
  }

  toggleContact = () => {
    this.setState({
      contactOpen: !this.state.contactOpen
    });
    document.body.classList.toggle('noscroll');
  }

  componentDidMount() {
    if(document.body.classList.contains('noscroll')) {
      document.body.classList.remove('noscroll');
    }
  }

  render() {
    return (
      <PageTransition
        defaultStyle={{
          transition: 'all 1100ms cubic-bezier(0.8, 0, 0.2, 1)',
          position: 'relative',
          width: '100%',
          opacity: 0,
          maxWidth: '2000px'

        }}
        transitionStyles={{
          entering: {
            opacity: 1
          },
          entered: {
            opacity: 1
          },
          exiting: {
            opacity: 0,
          }
        }}
        transitionTime={1250}
      >
        <Header 
          headerStyle="home"
          contactOpen={this.state.contactOpen}
          action={() => this.toggleContact()}
          {...this.props }
        />  


        <Hero
          subTitle="business_solutions"
          mainTitleTop="tailor_made_perfect_fit"
        />

        <Products
          cards = {cards}
        />

        <Services />

        <News />

        <Globe />

        <CallToAction
          image={ctaImage}
          heading="tailor_made_perfect_fit"
          content="lets_get_in_touch"
          buttonLabel="contact_us"
          action={() => this.toggleContact()}
        />

        <Footer />
        
      </PageTransition>
    );
  }
};

export default withIntl(IndexPage)




// ./src/pages/index.jsx