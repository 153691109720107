import React from 'react'
import Icon from '../global/icons/standard-icon'
import HomeHeroSlider from '../sliders/home-hero-slider'
import MouseIcon from '../global/icons/animated-mouse'
import { facebook, twitter, linkedin } from '../../utils/social'
import { FormattedMessage } from 'react-intl'
import { Link } from '../../i18n'

const Hero = ({ subTitle, mainTitleTop }) => (

  <section className="homehero bg-white">
    {/* Site Title */}
    <div className="row expanded">
      <div className="small-12 medium-push-1 medium-7 large-push-1 large-5 xlarge-push-1 xlarge-4  xxlarge-push-2 xxlarge-4 columns">
        <main className="homehero-heading__wrap">
          <div className="homehero-heading mpad">
            <em><FormattedMessage id={subTitle} /></em>
            <h1 className="dark"><span className="top"><FormattedMessage id={mainTitleTop} /></span></h1>
            {/* Vacancies */}
            <Link to="/case-studies/" className="homehero-jobs">
                <Icon icon="chevron-right" />
                <em className="noline">
                    <FormattedMessage id="case_studies" />
                </em>
            </Link>
          </div>
        </main>
      </div>
    </div>

    {/* Social */}
    <div className="homehero-social bg-light no-print">
      {/* Vertical Label */}
      <div className="homehero-social__label">
        <em className="grey"><FormattedMessage id="get_social" /></em>
      </div>
      
      {/* Social Icons */}
      <div className="homehero-social__links">
        <a href={facebook} className="facebook" target="_blank">
          <Icon icon="facebook" />
        </a>

        <a href={linkedin} className="linkedin" target="_blank">
          <Icon icon="linkedin" />
        </a>

        <a href={twitter} className="twitter" target="_blank">
          <Icon icon="twitter" />
        </a>
      </div>

    </div>


    {/* Slider */}
    <div className="homehero-slider">
      <HomeHeroSlider />
    </div>


    {/* Scroll Down */}
    <div className="homehero-scroll">
      <MouseIcon />
    </div>



  </section>
);

export default Hero




