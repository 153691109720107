import React from 'react'
import { FormattedMessage } from 'react-intl'

const Slide = ({ heading, content }) => (
    <div className="homeservices-slider__slide">
        <div className="valign-large mpad">
            <div className="row expanded pad">
                <div className="small-12 large-10 large-centered xlarge-8 columns print-stretch">
                    <em className="noline"><FormattedMessage id={heading} /></em>
                    <p className="light"><FormattedMessage id={content} /></p>
                </div>
            </div>
        </div>
    </div>
)

export default Slide



