import React from "react";
import Slider from "react-slick";

// Images
// import heroImage1 from '../../images/example-hero-image.jpg'
// import heroImage2 from '../../images/hero-image-two.jpg'
// import heroImage3 from '../../images/hero-image-three.jpg'
// import heroImage4 from '../../images/hero-image-four.jpg'
// import heroImage5 from '../../images/hero-image-five.jpg'

import heroImage1 from '../../images/homeheros/hero-1.jpg'
import heroImage2 from '../../images/homeheros/hero-2.jpg'
import heroImage3 from '../../images/homeheros/hero-3.jpg'
import heroImage4 from '../../images/homeheros/hero-4.jpg'
import heroImage5 from '../../images/homeheros/hero-5.jpg'

class homeHeroSlider extends React.Component {
  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      fade: true,
      speed: 3000,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
        <div className="homehero-slider__wrap">
            <Slider {...settings}>


                <div className="homehero-slider__slide">
                    <img src={heroImage1} alt="Slide Image"/>
                </div>

                <div className="homehero-slider__slide">
                    <img src={heroImage2} alt="Slide Image"/>
                </div>

                <div className="homehero-slider__slide">
                    <img src={heroImage3} alt="Slide Image"/>
                </div>

                <div className="homehero-slider__slide">
                    <img src={heroImage4} alt="Slide Image"/>
                </div>

                <div className="homehero-slider__slide">
                    <img src={heroImage5} alt="Slide Image"/>
                </div>
            </Slider>
        </div>
    );
  }
}

export default homeHeroSlider




