import React from 'react'
import HomeServiceSlider from '../sliders/home-service-slider/slider'
import OurServiceHomeSlider from '../sliders/home-service-slider/our-services-slider'

const Services = () => (
    <section className="homeservices bg-dark pad-top">
        <OurServiceHomeSlider/>
        <HomeServiceSlider />
        
    </section>
)

export default Services




